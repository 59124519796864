<template>
  <v-layout row wrap>
    <v-flex xs12 md6>
      <v-flex xs12 v-if="parkinfo">
        <park-table :parkinfo="parkinfo" :stats="stats"></park-table>
      </v-flex>
      <v-flex xs12 v-else>
        <v-progress-circular :size="100" :width="10" color="primary" indeterminate></v-progress-circular>
      </v-flex>

      <!-- <v-flex xs12 v-if="parkinfo">
        <json-card :jsoninfo="parkinfo" :title="parkinfo.reference"></json-card>
      </v-flex> -->

      <v-flex v-if="leaderboard">
        <h4>Park Leaders</h4>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th colspan="2">Activations</th>
                <th></th>
                <th colspan="2">Activator QSOs</th>
                <th></th>
                <th colspan="2">Hunter QSOs</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="index in 5" :key="index">
                <th>{{ index }}.</th>
                <td v-if="leaderboard.activations.length >= index"><user-stats :callsign="leaderboard.activations[index-1].callsign" nobadge></user-stats></td><td v-else></td>
                <td v-if="leaderboard.activations.length >= index">{{ leaderboard.activations[index-1].count }}</td><td v-else></td>
                <td></td>
                <td v-if="leaderboard.activator_qsos.length >= index"><user-stats :callsign="leaderboard.activator_qsos[index-1].callsign" nobadge></user-stats></td><td v-else></td>
                <td v-if="leaderboard.activator_qsos.length >= index">{{ leaderboard.activator_qsos[index-1].count }}</td><td v-else></td>
                <td></td>
                <td v-if="leaderboard.hunter_qsos.length >= index"><user-stats :callsign="leaderboard.hunter_qsos[index-1].callsign" nobadge></user-stats></td><td v-else></td>
                <td v-if="leaderboard.hunter_qsos.length >= index">{{ leaderboard.hunter_qsos[index-1].count }}</td><td v-else></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>

    </v-flex>

    <v-flex xs12 md6>
      <v-flex v-if="parkinfo">
        <!--<map-card v-bind:key="parkinfo.reference" v-bind:info="parkinfo"></map-card>-->
        <map-static-card :lat="parkinfo.latitude" :lon="parkinfo.longitude" :zoom="parkinfo.zoom" :parkinfo="parkinfo"></map-static-card>
      </v-flex>

      <v-flex xs12 class="hidden-xs-only hidden-sm-and-up">{{ this.id }}</v-flex>

      <v-flex v-if="activations">
        <h4>Activations</h4>
        <v-data-table
          :headers="activationsHeaders" 
          :items="activations">
          <template v-slot:[`item.qso_date`]="{ item }">
            {{ item.qso_date | date }}
          </template>
          <template v-slot:[`item.activeCallsign`]="{ item }">
            <user-stats :callsign="item.activeCallsign" nobadge></user-stats>
          </template>
        </v-data-table>
      </v-flex>

      <!--<v-flex>
        <h3>Activator Comments <park-comment :reference="parkinfo.reference" /></h3>
        <v-list two-line>
        <template v-for="comment in comments">
          <v-list-item :key="comment.commentId">
            <v-list-item-content>
              <v-list-item-title><user-stats :callsign="comment.activeCallsign"> on {{ $dayjs(comment.commentDate).format('L LT') }}:</user-stats><park-comment-remove :commentId="comment.commentId" :reference="parkinfo.reference" /></v-list-item-title>
              <v-list-item-subtitle v-html="comment.comments"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-flex>-->
      
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import ParkTable from '@/components/ParkTable'
import MapStaticCard from '@/components/MapStaticCard'
import UserStats from '@/components/UserStats'
//import ParkComment from '@/components/ParkComment'
//import ParkCommentRemove from '@/components/ParkCommentRemove'
import axios from 'axios'

export default {
  beforeMount() {
    //alert('beforeMount ' + this.id)

  },
  // mounted () {
  //   alert('mounted ' + this.id)
  //   this.updateParkInfo()
  // },
  beforeCreate() {
    // alert('beforeCreate ' + this.id)
    // this.updateParkInfo()
  },
  created() {
    //alert('created ' + this.id)
    this.updateParkInfo(this.id)
  },
  beforeUpdate() {
    // alert('beforeUpdate ' + this.id)
    // this.updateParkInfo()
  },
  updated() {
    // alert('updated ' + this.id)
    this.updateParkInfo(this.id)
  },
  methods: {
    updateParkInfo(id) {
      if (this.oldid != id) {
        var requestInfo = axios.get(`https://${process.env.VUE_APP_API_URL}/park/${id}`)
        var requestStats = axios.get(`https://${process.env.VUE_APP_API_URL}/park/stats/${id}`)
        var requestLeader = axios.get(`https://${process.env.VUE_APP_API_URL}/park/leaderboard/${id}?count=5`)
        var requestActivations = axios.get(`https://${process.env.VUE_APP_API_URL}/park/activations/${id}?count=all`)

        requestInfo.then((response) => {
//          if (!response.data.zoom)
            response.data.zoom = 9;
          this.getParkInfo(response.data)
        })
        requestStats.then((response) => {
          this.stats = response.data
        })
        requestActivations.then((response) => {
          this.activations = response.data
        })
        requestLeader.then((response) => {
          this.leaderboard = response.data
        })

        //axios
        //  .get(`https://${process.env.VUE_APP_API_URL}/park/comments/${id}`)
        //  .then(response => response.data)
        //  .then(json => this.comments = json)
      }
      this.oldid = id
    },
    getParkInfo(parkinfo) {
      this.parkinfo = parkinfo
      this.$route.meta.title = this.parkinfo.name + ' ' + this.parkinfo.parktypeDesc
    },
    addComment() {}
  },
  data () {
    return {
      parkinfo: null,
      activations: null,
      comments: {},
      leaderboard: null,
      stats: null,
      userComment: '',
      oldid: '',
      activationsHeaders: [
        { text: 'Date', value: 'qso_date' },
        { text: 'Callsign', value: 'activeCallsign' },
        { text: 'CW', value: 'qsosCW' },
        { text: 'Data', value: 'qsosDATA' },
        { text: 'Phone', value: 'qsosPHONE' },
        { text: 'Total QSOs', value: 'totalQSOs'}
      ],
    }
  },
  props: [ 'id' ],
  computed: mapState({
    tableView: state => state.config.tableView,
    parks: state => state.parks.parks,
    isAuthenticated: state => state.user.isAuthenticated,
    isParkManager: state => state.user.isParkManager
  }),
  components: {
    ParkTable,
    MapStaticCard,
    UserStats,
    //ParkComment,
    //ParkCommentRemove
  }
}
</script>
