<template>
  <v-container v-if="profile" fluid>
    <v-row>
      <v-col xs12 md2 lg2>
        <v-img max-width="250" max-height="250" class="mx-auto" :src="`https://www.gravatar.com/avatar/${this.profile.gravatar}?s=250&d=identicon`" />
      </v-col>

      <v-col xs12 md4 lg2 class="text-center my-auto">
        <p class="text-h2" style="white-space: nowrap;"><b>{{ this.profile.callsign }}</b><span v-if="this.profile.sk == 1"> (SK)</span></p>
        <p class="text-h3" style="white-space: nowrap;" v-if="this.profile.name && this.profile.name != ''">{{ this.profile.name }}</p>
        <p class="text-h6" style="white-space: nowrap;" v-if="this.profile.qth && this.profile.qth != ''">{{ this.profile.qth }}</p>
        <p>Other callsigns: {{ this.profile.other_callsigns.join(', ') }}</p>
        <p v-if="showAdminLinks && adminRoutes && adminRoutes.length > 0">
          <ul id="admin-links">
            <li>Admin:&nbsp;</li>
            <li v-for="link in adminRoutes" :key="link.route">
              <router-link :to="{name: link.route, params: { userId: profile.id }}">{{ link.name }}</router-link>
            </li>
          </ul>
        </p>
      </v-col>

      <v-col xs12>
        <p class="text-h4 mt-6">All-Time Statistics</p>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th>Activator</th>
                <th>Hunter</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Activations</th>
                <td>{{ profile.stats.activator.activations }} / {{ profile.stats.attempts.activations }}
                </td>
                <td>----</td>
              </tr>
              <tr>
                <th>Parks</th>
                <td>{{ profile.stats.activator.parks }} / {{ profile.stats.attempts.parks }}</td>
                <td>{{ profile.stats.hunter.parks }}
                </td>
              </tr>
              <tr>
                <th>QSOs</th>
                <td>{{ profile.stats.activator.qsos }} / {{ profile.stats.attempts.qsos }}</td>
                <td>{{ profile.stats.hunter.qsos }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      </v-row>

    <v-row><v-col><p class="text-h4 mt-6">Awards</p></v-col></v-row>
    <v-row>
      <v-col v-for="item in profile.awards" :key="item.name + item.granted" cols="6" sm="4" md="3" lg="2" xl="1">
        <v-img class="ma-auto" width="150" :src="`//static.pota.app/v0/certificate/thumbnails/${item.name}.png`"></v-img>
        <p class="text-center">{{ item.name }}</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="6">
        <p class="text-h4 mt-6">Recent Activations</p>

        <v-data-table :headers="headers_activations" :items="profile.recent_activity.activations" :hide-default-header="isMobile()" hide-default-footer disable-sort>
          <template #item="{item, isMobile}">
            <tr v-if="!isMobile">
              <td>{{ $dayjs(item.date).format('L') }}</td>
              <td><router-link :to="`/park/${item.reference}`">{{ item.reference }} {{ item.park }} ({{ item.location }})</router-link></td>
              <td>{{ item.cw }}</td>
              <td>{{ item.data }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.total }}</td>
            </tr>
            <tr v-else>
              <td class="px-0 py-3">
                <v-container class="pa-0">
                  <v-row no-gutters>
                    <v-col><strong>Park:</strong> <router-link :to="`/park/${item.reference}`">{{ item.reference }} {{ item.park }} ({{ item.location }})</router-link></v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col><strong>Date:</strong> {{ $dayjs(item.date).format('L') }}</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col><strong>CW:</strong> {{ item.cw }}</v-col>
                    <v-col><strong>Phone:</strong> {{ item.phone }}</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col><strong>Data:</strong> {{ item.data }}</v-col>
                    <v-col><strong>Total:</strong> {{ item.total }}</v-col>
                  </v-row>
                </v-container>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12" lg="6">
        <p class="text-h4 mt-6">Recent Hunter QSOs</p>

        <v-data-table :headers="headers_hunted_qsos" :items="profile.recent_activity.hunter_qsos" :hide-default-header="isMobile()" hide-default-footer disable-sort>
          <template #item="{item, isMobile}">
            <tr v-if="!isMobile">
              <td>{{ $dayjs(item.date).format('L') }}</td>
              <td><user-stats :callsign="item.callsign" /></td>
              <td><router-link :to="`/park/${item.reference}`">{{ item.reference }} {{ item.park }} ({{ item.location }})</router-link></td>
              <td>{{ item.band }}</td>
              <td>{{ item.mode }}</td>
            </tr>
            <tr v-else>
              <td class="px-0 py-3">
                <v-container class="pa-0">
                  <v-row no-gutters>
                    <v-col><strong>Park:</strong> <router-link :to="`/park/${item.reference}`">{{ item.reference }} {{ item.park }} ({{ item.location }})</router-link></v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col><strong>Date:</strong> {{ $dayjs(item.date).format('L') }}</v-col>
                    <v-col><strong>Activator:</strong> <user-stats :callsign="item.callsign" /></v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col><strong>Band:</strong> {{ item.band }}</v-col>
                    <v-col><strong>Mode:</strong> {{ item.mode }}</v-col>
                  </v-row>
                </v-container>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!--
    <v-row>
      <v-col>
        <json-card :jsoninfo="profile" :title="profile.callsign"></json-card>
      </v-col>
    </v-row> 
    -->
  </v-container>
</template>

<style scoped>
#admin-links li {
  display: inline;
}
#admin-links > li:not(:last-child):not(:first-child)::after {
    content: " | ";
}

</style>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
// import JsonCard from '@/components/JsonCard'
import UserStats from '@/components/UserStats'

export default {
  created() {
    this.updateProfile(this.callsign)
  },
  updated() {
    this.updateProfile(this.callsign)
  },
  methods: {
    isMobile() {
      if (window.innerWidth < 600)
        return true
      return false
    },
    updateProfile(callsign) {
      if (this.oldcallsign != callsign) {
        var sanitizedCallsign = this.callsign.match(/^[A-Z\d]+/);
        if (sanitizedCallsign == null)
          return;

        sanitizedCallsign = sanitizedCallsign[0];
        this.$route.meta.title = sanitizedCallsign;
        document.title = sanitizedCallsign;

        axios
          .get(`https://${process.env.VUE_APP_API_URL}/profile/${sanitizedCallsign}`)
          .then(response => response.data)
          .then(json => this.getProfile(json))
      }
      this.oldcallsign = callsign
    },
    getProfile(profile) {
      this.profile = profile
      this.$route.meta.title = this.profile.callsign
      document.title = this.profile.callsign;
    },
  },
  data () {
    return {
      profile: null,
      oldcallsign: null,
      headers_activations: [{
            text: 'Date',
            align: 'left',
            value: 'date'
          },
          {
            text: 'Park',
            align: 'left',
            value: 'park'
          },
          {
            text: 'CW',
            value: 'cw'
          },
          {
            text: 'Data',
            value: 'data'
          },
          {
            text: 'Phone',
            value: 'phone'
          },
          {
            text: 'Total QSOs',
            value: 'total'
          }
      ],
      headers_hunted_qsos: [{
            text: 'Date',
            align: 'left',
            value: 'date'
          },
          {
            text: 'Activator',
            align: 'left',
            value: 'operator'
          },
          {
            text: 'Park',
            align: 'left',
            value: 'park'
          },
          {
            text: 'Band',
            value: 'band'
          },
          {
            text: 'Mode',
            value: 'mode'
          }
      ]
    }
  },
  props: [ 'callsign' ],
  computed: mapState({
    isAuthenticated: state => state.user.isAuthenticated,
    isUserSupport: state => state.user.isUserSupport,
    isLogManager: state => state.user.isLogManager,
    showAdminLinks: state => state.user.isUserSupport || state.user.isLogManager,
    adminRoutes: state => {
      var routes = [];

      if (state.user.isUserSupport)
      {
        routes.push({ route: 'user_stats_admin', name: 'Stats' });
        routes.push({ route: 'user_awards_admin', name: 'Awards' });
        routes.push({ route: 'user_activations_admin', name: 'Activations' });
        routes.push({ route: 'user_logbook_admin', name: 'Logbook' });
        routes.push({ route: 'user_logs_admin', name: 'Logs' });
        routes.push({ route: 'user_account_admin', name: 'Account' });
      }

      return routes;
    }
  }),
  watch: {
    $route(to) {
      this.updateProfile(to.params.callsign)
    }
  },
  components: {
    // JsonCard,
    UserStats
  }
}
</script>
