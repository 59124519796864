<template>
  <v-container no-gutters pa-0 fluid class="quiet-links">
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row no-gutters>
          <v-col class="col-12 col-sm-12 col-md-12 col-lg-8" align-self="end"><location-selector @selected="selectLocation" /></v-col>
          <v-col class="col-12 col-sm-6 col-md-3 offset-md-6 col-lg-2 offset-lg-0" align="right" align-self="end">
            <v-menu offset-y v-if="parks && userStats" >
              <template v-slot:activator="{ on, attrs }">
                <v-btn text color="primary" dark v-bind="attrs" v-on="on">
                  Download&nbsp;<span style="color: orange;">&#x25bc;</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="downloadCSV()">
                  <v-list-item-title>CSV</v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadGPX()">
                  <v-list-item-title>GPX</v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadKML()">
                  <v-list-item-title>KML</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col class="col-12 col-sm-6 col-md-3 col-lg-2" align-self="end">
            <v-text-field v-model="search" placeholder="Search for Park" hide-details clearable :prepend-inner-icon="$vuetify.icons.values.mdiMagnify"></v-text-field>
          </v-col>
          </v-row>
        </v-container>
        <v-data-table
          :headers="userStats ? parksHeaders : parksHeadersUnAuth" 
          :items="parks" 
          :search="search"
          :loading="loading"
          loading-text="Loading parks..."
          fluid
          :footer-props="{
            'items-per-page-options': [25, 50, 100, 250]
          }"
          :items-per-page="50">
          <template v-slot:item.reference="{ item }">
            <span>
              <router-link :to="{ name: 'park', params: { id: item.reference } }">
                {{ item.reference }}
              </router-link>
            </span>
          </template>
          <template v-slot:item.name="{ item }">
            <span>
              <router-link :to="{ name: 'park', params: { id: item.reference } }">
                {{ item.name }}
              </router-link>
            </span>
          </template>
          <template v-slot:item.locationDesc="{ item }">
            <v-tooltip right v-if="item.locationDesc.includes(',')">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ item.locationDesc.split(',')[0].concat(', + ', (item.locationDesc.split(',').length - 1)) }}
                    </span>
                  </template>
                  <span>{{ item.locationDesc }}</span>
                </v-tooltip>
                <span v-else>{{ item.locationDesc }}</span>
          </template>
          <template v-slot:item.grid="{ item }">
            <span>{{ item.grid.substring(0,4) }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios"
import LocationSelector from '@/components/LocationSelector'

export default {
  props: ["preselectLocation"],
  components: {
    LocationSelector
  },
  data() {
    return {
      search: '',
      parksHeadersUnAuth: [
        { text: 'Reference', value: 'reference' },
        { text: 'Park', value: 'name', width: '40%', cellClass: 'text-no-wrap ellipsis' },
        { text: 'Location(s)', value: 'locationDesc' },
        { text: 'Grid', value: 'grid' },
        { text: 'Attempts', value: 'attempts' },
        { text: 'Activations', value: 'activations' },
        { text: 'QSOs', value: 'qsos' },
      ],
      parksHeaders: [
        { text: 'Reference', value: 'reference' },
        { text: 'Park', value: 'name', width: '40%', cellClass: 'text-no-wrap ellipsis' },
        { text: 'Location(s)', value: 'locationDesc' },
        { text: 'Grid', value: 'grid' },
        { text: 'Attempts', value: 'attempts' },
        { text: 'Activations', value: 'activations' },
        { text: 'QSOs', value: 'qsos' },
        { text: 'My Activations', value: 'my_activations' },
        { text: 'My Hunted QSOs', value: 'my_hunted_qsos' }
      ],
      parks: [],
      locationType: null,
      locationId: null,
      locationPath: null,
      loading: false,
      userStats: null
    };
  },
  computed: {
    isAuthenticated: {
      get() {
        return this.$store.state.user.isAuthenticated
      }
    },
  },
  beforeMount() {
  },
  mounted () {
  },
  methods: {
    selectLocation(type, id, path) {
      if (this.locationType != type || this.locationId != id)
      {
        this.loading = true;
        this.locationType = type;
        this.locationId = id;
        this.locationPath = path;
        this.parks = [];
        this.userStats = null;

        var requestUser = null;
        if (!this.userStats && this.isAuthenticated)
          requestUser = axios.get(`https://${process.env.VUE_APP_API_URL}/user/stats/park`, this.$store.getters.authTokenHeader)

        var requestParks = null;
        
        if (type == "program")
          requestParks = axios.get(`https://${process.env.VUE_APP_API_URL}/program/parks/${id}`);
        else if (type == "entity")
          requestParks = axios.get(`https://${process.env.VUE_APP_API_URL}/entity/parks/${id}`);
        else
          requestParks = axios.get(`https://${process.env.VUE_APP_API_URL}/location/parks/${id}`);

        var requests = [requestParks];
        if (requestUser)
          requests = [requestParks, requestUser];

        axios.all(requests)
          .then(axios.spread((...responses) => {
            if (responses.length > 1)
              this.userStats = responses[1].data;

            var data = responses[0].data;

            if (this.userStats)
            {
              for (var i = 0; i < data.length; i++)
              {
                if (this.userStats.activations[data[i].reference])
                  data[i].my_activations = this.userStats.activations[data[i].reference];
                else
                  data[i].my_activations = 0;

                if (this.userStats.hunted[data[i].reference])
                  data[i].my_hunted_qsos = this.userStats.hunted[data[i].reference];
                else
                  data[i].my_hunted_qsos = 0;
              }
            }

            this.parks = data;
            this.loading = false;
          }))
          .catch(err => {
            console.log(err);
          });
      }
    },
    updateSearch(value) {
      this.search = value;
    },
    convertArrayOfObjectsToCSV(args) {
      // https://stackoverflow.com/a/34043817
      var result, ctr, keys, columnDelimiter, lineDelimiter, data;

      data = args.data || null;
      if (data == null || !data.length) {
          return null;
      }

      columnDelimiter = args.columnDelimiter || ',';
      lineDelimiter = args.lineDelimiter || '\n';

      keys = Object.keys(data[0]);

      result = '';
      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      data.forEach(function(item) {
          ctr = 0;
          keys.forEach(function(key) {
              if (ctr > 0) result += columnDelimiter;

              // add check for values containing commas
              var val = item[key];
              if (val != null)
              {
                val = String(val);
                if (val.includes('"'))
                  val = val.replaceAll('"', '""'); // escape quotes
                if (val.includes(','))
                  result += '"' + val + '"';
                else
                  result += val;
              }

              ctr++;
          });
          result += lineDelimiter;
      });

      return result;
    },
    currentPathName() {
      if (!this.locationPath)
        return 'parks';

      const parts = this.locationPath.split('▸');
      const name = parts[parts.length - 1];

      return name.trim();
    },
    downloadFile(content, mime, filename) {
      var blob = new Blob([content], { type: mime });
      var url = URL.createObjectURL(blob);

      // Create a link to download it
      var pom = document.createElement('a');
      pom.href = url;
      pom.setAttribute('download', filename);
      pom.click();
    },
    downloadCSV() {
      if (!this.parks || !this.userStats)
        return;

      var csv = this.convertArrayOfObjectsToCSV({ data: this.parks });

      this.downloadFile(csv, 'text/csv;charset=utf-8;', this.currentPathName() + '.csv');
    },
    downloadGPX() {
      if (!this.parks || !this.userStats)
        return;

      var gpx = '<?xml version="1.0" encoding="utf-8" standalone="yes"?>\n';
      gpx += '<gpx version="1.1" creator="Parks on the Air https://www.parksontheair.com/" xmlns="http://www.topografix.com/GPX/1/1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">\n';

      for (var i = 0; i < this.parks.length; i++)
      {
        var park = this.parks[i];
        gpx += `<wpt lat="${park.latitude}" lon="${park.longitude}"><name>${park.reference} ${park.name.replaceAll('&', '&amp;')}</name><link href="${window.location.origin}/#/park/${park.reference}"><text>Park Information</text></link></wpt>\n`;
      }

      gpx += '</gpx>';

      this.downloadFile(gpx, 'application/gpx+xml;', this.currentPathName() + '.gpx');
    },
    downloadKML() {
      if (!this.parks || !this.userStats)
        return;

      var kml = '<?xml version="1.0" encoding="utf-8" standalone="yes"?>\n';
      kml += '<kml xmlns="http://www.opengis.net/kml/2.2">\n<Document>\n';

      for (var i = 0; i < this.parks.length; i++)
      {
        var park = this.parks[i];
        kml += `<Placemark><name>${park.reference} ${park.name.replaceAll('&', '&amp;')}</name><description><![CDATA[<a href="${window.location.origin}/#/park/${park.reference}">Park Information</a>]]></description><Point><coordinates>${park.longitude},${park.latitude}</coordinates></Point></Placemark>\n`;
      }

      kml += '</Document>\n</kml>';

      this.downloadFile(kml, 'application/vnd.google-earth.kml+xml;', this.currentPathName() + '.kml');
    }
  },
  watch: {
  }
}
</script>

