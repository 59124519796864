<template>
  <div>
    <v-btn text color="primary" dark @click="showMenu" ref="locationSelectButton">
      <span v-html="dropdownText.replaceAll('▸', '<span style=\'color: orange;\'>▸</span>')"></span>&nbsp;<span style="color: orange;">&#x25bc;</span>
    </v-btn>
  <v-menu v-model="show" :position-x="x" :position-y="y" absolute :close-on-content-click="false">
    <v-card>
      <v-toolbar flat class="primary lighten-2">
        <v-text-field
          v-model="search"
          label="Search For Location"
          dark
          flat
          solo-inverted
          hide-details
          clearable
          :prepend-inner-icon="$vuetify.icons.values.mdiMagnify">
        </v-text-field>
      </v-toolbar>
      <template v-if="items">
        <v-treeview
          style="height: 70vh; overflow-y: scroll; margin: 0; cursor: pointer;"
          :items="items"
          :search="search"
          :active.sync="active"
          :open.sync="open"
          return-object
          dense
          hoverable
          activatable>
          <template v-slot:prepend="{ item }">
            <v-icon v-if="item.programId" tooltip="Program">{{$vuetify.icons.values.mdiFolderMarker}}</v-icon>
            <v-icon v-else-if="item.entityId" tooltip="Entity">{{$vuetify.icons.values.mdiMapMarkerMultipleOutline}}</v-icon>
            <v-icon v-else tooltip="Location">{{$vuetify.icons.values.mdiMapMarkerRadius}}</v-icon>
          </template>
          <template v-slot:label="{ item }">
            <template v-if="item.programId">
              {{ item.name }} ({{ item.prefix }})
            </template>
            <template v-else-if="item.entityId">
              {{ item.name }}
            </template>
            <template v-else>
              {{ item.name }} ({{ item.descriptor }})
            </template>
          </template>
          <template v-slot:append="{ item }">
            <v-badge inline :content="item.parks ? item.parks : '0'"></v-badge>
          </template>
        </v-treeview>
      </template>
      <div class="text-center" style="height: 70vh;" v-else>
        <v-progress-circular :size="100" :width="10" color="primary" indeterminate></v-progress-circular>
      </div>
    </v-card>
  </v-menu>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
  },
  data() {
    return {
      items: null,
      search: null,
      active: [],
      open: [],
      show: false,
      x: 0,
      y: 0
    };
  },
  mounted() {
      this.loadPrograms();
      this.showMenu(null);
  },
  watch: {
    active(newValue) {
      if (newValue != null && newValue.length > 0)
      {
        var active = newValue[0];

        var type;
        var id;
        var path;

        if (active.id.startsWith('p:'))
        {
          type = 'program';
          id = active.prefix;
          path = active.path;
        }
        else if (active.id.startsWith('e:'))
        {
          type = 'entity';
          id = active.entityId;
          path = active.path;
        }
        else if (active.id.startsWith('l:'))
        {
          type = 'location';
          id = active.descriptor;
          path = active.path;
        }

        this.show = false;

        this.$emit('selected', type, id, path);
      }
    }
  },
  computed: {
    dropdownText: function() {
      if (!this.active || this.active.length == 0)
        return "Select Location"
      
      return this.active[0].path;
    },
    callsign: function() {
      if (this.$store.state.user.isAuthenticated)
        return this.$store.state.user.callsign;
      return '';
    }
  },
  methods: {
    showMenu(e) {
      var btn = this.$refs.locationSelectButton;
      var rect = btn.$el.getBoundingClientRect();

      if (e)
        e.preventDefault();

      this.show = false;
      this.x = rect.left + window.scrollX;
      this.y = rect.bottom + window.scrollY;

      this.$nextTick(() => {
        this.show = true;
      });
    },
    loadPrograms() {
      if (this.items) return;

      axios
        .get(`https://${process.env.VUE_APP_API_URL}/programs/locations`)
        .then((response) => response.data)
        .then((programs) => {
          if (this.restrictProgram)
            programs = programs.filter(program => program.prefix == this.restrictProgram);

          var open = [];

          var callsign = this.callsign;
          var isUS = /^[KNW].+/.test(callsign);

          programs.forEach((program) => {
            program.id = 'p:' + program.programId;
            program.path = `${program.name} (${program.prefix})`;

            if (program.prefix == 'K' && isUS)
              open.push(program);
            else if (!isUS && callsign.startsWith(program.prefix))
              open.push(program);

            program.entities.forEach((entity) => {
              entity.id = 'e:' + entity.entityId;
              entity.path = `${program.path} ▸ ${entity.name}`;

              if (program.prefix == 'K' && entity.name.startsWith('United States'))
                open.push(entity);

              entity.locations.forEach(location => {
                location.id = 'l:' + location.locationId;
                location.path = `${entity.path} ▸ ${location.name} (${location.descriptor})`;
              });

              entity.children = entity.locations;
              delete entity.locations;
            });

            if (program.entities.length == 1)
              open.push(program.entities[0]);

            program.children = program.entities;
            delete program.entities;
          });

          this.items = programs;
          this.open = open;
        });
    },
  },
  components: {},
};
</script>
