<template>

  <v-card fill-height>
    
    <v-img v-if="parkinfo.heroImage"
      class="white--text align-end"
      height="200px"
      src="https://www.nps.gov/common/uploads/banner_image/imr/homepage/8E9F91A3-1DD8-B71B-0B763387D1AACB61.jpg?width=2400&height=700&mode=crop&quality=90"
    >
      <v-card-title class="title">{{ parkinfo.name }} {{ parkinfo.parktypeDesc }}</v-card-title>
    </v-img>
    
    <v-card-title v-else class="primary title">
      {{ parkinfo.name }} {{ parkinfo.parktypeDesc }}
      <v-spacer></v-spacer>
      <v-btn v-if="isParkAdmin"
        color="secondary"
        small
        outlined
        :to="{ name: 'park_edit', params: { reference: parkinfo.reference }}">
        Edit
      </v-btn>
    </v-card-title>

    <v-divider></v-divider>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr v-if="parkinfo.active == 0">
            <td colspan=2 class="error text-center"><strong>PARK NOT ACTIVE - DO NOT ACTIVATE</strong></td>
          </tr>
          <tr>
            <th>Reference:</th>
            <td class="text-right">{{ parkinfo.reference }}</td>
          </tr>
          <tr>
            <th>Entity:</th>
            <td class="text-right">{{ parkinfo.entityName }}</td>
          </tr>
          <tr>
            <th>Location(s):</th>
            <td class="text-right">{{ parkinfo.locationDesc ? parkinfo.locationDesc.replaceAll(',', ', ') : '' }}</td>
          </tr>
          <tr>
            <th>Latitude / Longitude:</th>
            <td class="text-right">{{ parkinfo.latitude }}, {{ parkinfo.longitude }} ({{ parkinfo.grid6 }})</td>
          </tr>
          <tr>
            <th>Early Shift Award Times:</th>
            <td class="text-right" v-html="earlyShift"></td>
          </tr>
          <tr>
            <th>Late Shift Award Times:</th>
            <td class="text-right" v-html="lateShift"></td>
          </tr>
          <!--<tr v-if="parkinfo.parkURLs && parkinfo.parkURLs.length > 0">
            <th>Website:</th>
            <td class="text-right">
              <template v-for="url in getParkURLs">
                &nbsp;<a :key="url" :href="url" target="_new">{{ url }}</a>
              </template>
            </td>
          </tr>-->
          <tr v-if="parkinfo.website && parkinfo.website.length > 0">
            <th>Website:</th>
            <td class="text-right ellipsis">
              <span><a :href="parkinfo.website" target="_new">{{ parkinfo.website }}</a></span>
            </td>
          </tr>
          <!--<tr v-if="parkinfo.agencies && parkinfo.agencies.length > 0">
            <th>Agencies:</th>
            <td class="text-right">
              <template v-for="agency in getAgencyURLs">
                &nbsp;<a :key="agency.url" :href="agency.url" target="_new">{{ agency.name }}</a>
              </template>
            </td>
          </tr>-->
          <tr v-if="parkinfo.accessMethods && parkinfo.accessMethods.length > 0">
            <th>Access Methods:</th>
            <td class="text-right">
              <template  v-for="method in parkinfo.accessMethods.split(',')">
                &nbsp;<v-chip :key="method" small>{{ method }}</v-chip>
              </template>
            </td>
          </tr>
          <tr v-if="parkinfo.activationMethods && parkinfo.activationMethods.length > 0">
            <th>Activation Methods:</th>
            <td class="text-right">
              <template  v-for="method in parkinfo.activationMethods.split(',')">
                &nbsp;<v-chip :key="method" small>{{ method }}</v-chip>
              </template>
            </td>
          </tr>
          <tr>
            <th>Is Active?</th>
            <td class="text-right">{{ parkinfo.active | isActive }}</td>
          </tr>
          <tr>
            <th>First Activation:</th>
            <td v-if="parkinfo.firstActivator" class="text-right">
              <user-stats :callsign="parkinfo.firstActivator"></user-stats> on {{ parkinfo.firstActivationDate | date }}
            </td>
            <td v-else class="text-right">Never activated</td>
          </tr>
          <tr v-if="stats">
            <th>Stats:</th>
            <td class="text-right">{{stats.activations}} activations / {{stats.attempts}} attempts ({{stats.contacts}} QSOs)</td>
          </tr>
        </tbody>
        <tfoot v-if="parkinfo.parkComments">
          <tr>
            <td colspan="2">{{ parkinfo.parkComments }}</td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </v-card>
</template>

<style scoped>
.ellipsis {
  position: relative;
}
.ellipsis:before {
  content: '&nbsp;';
  visibility: hidden;
}
.ellipsis span {
  position: absolute;
  left: 4px;
  right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<script>
import UserStats from '@/components/UserStats'

export default {
  props: {
    parkinfo: { required: true },
    stats: null
  },
  data () {
    return {
    }
  },
  filters: {
    isActive: function (value) {
      if (value != 1) {
        return "NOT ACTIVE" 
      }
      return "ACTIVE"
    }
  },
  computed: {
    isParkAdmin: function() {
      if (this.$store.getters.isParkAdmin(this.parkinfo.referencePrefix))
        return true;
      
      if (!this.parkinfo.locationDesc || this.parkinfo.locationDesc.length == 0)
        return false;

      var locations = this.parkinfo.locationDesc.split(',');
      if (locations.length > 1)
        return false;

      if (this.$store.getters.isParkAdmin(locations[0]))
        return true;

      return false;
    },
    getParkURLs: function() {
      if (!this.parkinfo.parkURLs || this.parkinfo.parkURLs.length == 0)
        return []

      return this.parkinfo.parkURLs.split(',')
    },
    getAgencyURLs: function() {
      if (!this.parkinfo.agencies || this.parkinfo.agencies.length == 0)
        return []

      var agencies = this.parkinfo.agencies.split(',')
      var urls = this.parkinfo.agencyURLs.split(',')

      var sites = []

      for (var i = 0; i < agencies.length; i++)
      {
        sites.push({ name: agencies[i], url: urls[i] })
      }

      return sites
    },
    earlyShift: function() {
      var returnStr = ""

      if (this.parkinfo.locationDesc) {
        // if multi-location
        if (this.parkinfo.locationDesc.includes(",")) {
          var locations = this.parkinfo.locationDesc.split(",")
          for (let index = 0; index < locations.length; index++) {
            const item = locations[index];
            returnStr += "<b>In " + item + ":</b> " + this.calcShiftOffset(this.parkinfo.longitude, 8) + ":00 UTC to " + this.calcShiftOffset(this.parkinfo.longitude, 14) + ":00 UTC<br/>"
          }
          returnStr = "Based on location, specific times coming soon."
        }
        if (!this.parkinfo.locationDesc.includes(",")) {
          // if not multi-location
          returnStr = this.calcShiftOffset(this.parkinfo.longitude, 8) + ":00 UTC to " + this.calcShiftOffset(this.parkinfo.longitude, 14) + ":00 UTC"
        }
      }
      return returnStr
    },
    lateShift: function() {
      var returnStr = ""

      if (this.parkinfo.locationDesc) {
        // if multi-location
        if (this.parkinfo.locationDesc.includes(",")) {
          var locations = this.parkinfo.locationDesc.split(",")
          for (let index = 0; index < locations.length; index++) {
            const item = locations[index];
            returnStr += "<b>In " + item + ":</b> " + this.calcShiftOffset(this.parkinfo.longitude, 0) + ":00 UTC to " + this.calcShiftOffset(this.parkinfo.longitude, 8) + ":00 UTC<br/>"
          }
          returnStr = "Based on location, specific times coming soon."
        }

        if (!this.parkinfo.locationDesc.includes(",")) {
          // if not multi-location
          returnStr = this.calcShiftOffset(this.parkinfo.longitude, 0) + ":00 UTC to " + this.calcShiftOffset(this.parkinfo.longitude, 8) + ":00 UTC"
        }
      }
      return returnStr
    }
  },
  methods: {
    calcShiftOffset(longitude, offsetHours) {
      return (18 - Math.round(longitude/15) + offsetHours) % 24
    }
  },
  components: {
    UserStats
  }
}
</script>
