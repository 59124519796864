<template>
  <v-card>
    <v-card-title>
      <v-select
        v-model="selectedYear"
        :items="selectYears"
        item-text="text"
        item-value="value"
        label="Year"
        single-line>
      </v-select>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" :prepend-icon="$vuetify.icons.values.mdiMagnify" label="Search" single-line></v-text-field>
    </v-card-title>
    <v-data-table :headers="headers" :items="activators" :loading="needLoad" :search="search" class="elevation-1" :footer-props="{'items-per-page-options': [10,25,100]}">
      <v-progress-linear slot="progress" color="green lighten-3" indeterminate></v-progress-linear>
      <template v-slot:[`item.activeCallsign`]="{ item }">
        <router-link :to="`/profile/${item.activeCallsign}`">{{ item.activeCallsign }}</router-link>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios'
import dayjs from 'dayjs'

export default {
    mounted () {
      this.loadActivators();

      this.selectYears = [
          { text: 'All-Time', value: 'all' }
        ];

      var currentYear = dayjs().utc().year();
      for (var year = currentYear; year >= 2016; year--)
        this.selectYears.push({ text: year, value: year });
    },
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Callsign',
            align: 'left',
            sortable: false,
            value: 'activeCallsign'
          },
          { text: 'Activations', value: 'activations', align: 'right'},
          { text: 'Parks', value: 'units', align: 'right'},
          { text: 'Total QSOs', value: 'TotalContacts', align: 'right' },
          { text: 'Total CW', value: 'TotalCWContacts', align: 'right' },
          { text: 'Total Data', value: 'TotalDataContacts', align: 'right' },
          { text: 'Total Phone', value: 'TotalPhoneContacts', align: 'right' },
        ],
        selectYears: [
          { text: 'All-Time', value: 'all' }
        ],
        selectedYear: 'all',
        activators: [],
        needLoad: true
      }
    },
    methods: {
      loadActivators() {
        if (!this.needLoad)
          return;

        this.activators = [];

        var year = '';
        if (this.selectedYear != 'all')
          year = `?year=${this.selectedYear}`;

        axios
          .get(`https://${process.env.VUE_APP_API_URL}/activator/all${year}`)
          .then(response => response.data)
          .then(json => {
            if (json)
              this.activators = json;

            this.needLoad = false;
          });
      }
    },
    watch: {
      selectedYear() {
        this.needLoad = true;
        this.loadActivators();
      }
    }
}
</script>
